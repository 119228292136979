body::-webkit-scrollbar {
  display: none;
}
/* .main_app {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
}
.main_app img {
  height: 100vh;
} */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
  text-align: center;
}

.loading-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3498db; /* Blue color */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-content h2 {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 500;
  color: #555;
}

